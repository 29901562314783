import React from "react"
import { Link } from "gatsby"
import { Text, Image } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"
import PageNumber from "../../../components/adventures/PageNumber"

import hatbatImage from '../../../images/spots/hatbat.png'

export default function Part02() {
  return (
    <Part number="1" title="Treasure Town" time="20–30 minutes">
      <Section type="Setup">
        <Block type="Secret Context" title="Setup">
          <Text>In this part of the adventure, the players will arrive in Treasure Town and receive instructions from The Trickster’s friend, Fiona Fairweather. She will explain where the adventurers are and how they can escape the trap.</Text>
        </Block>
      </Section>

      <Section type="Context">
        <Block type="Secret Context" title="Treasure Town">
          <Text>Treasure Town is The Trickster's vault. The museum in the center of the town is a shadow haven whose interior is much larger than what the building's outside appearance would suggest. The museum contains a large number of exhibits featuring various treasures. Each item is labeled with an explanation of its origin and a description of its effects.</Text>
          <Text>The town is populated by illusory inhabitants. They are not real people, and can only engage in a limited amount of conversation before repeating themselves. They all seem to enjoy a peaceful existence in the town.</Text>
          <Text>The curator of the museum is Fiona Fairweather (she/her): a friend of The Trickster who lives in the town by herself. She is also a powerful Magician who helps maintain The Trickster's realm and protects its treasure. She is outwardly friendly, but will take a serious tone if the adventurers try to mess with the town or take more treasure than they are allowed.</Text>
          <Text>If the players decide to explore the town, they will not find anything of note except for what looks like a peaceful and well-maintained village. If they talk to the villagers, they will not learn anything except that the villagers are welcoming and love life in the town. The villagers will direct the adventurers to the museum in the center of town where they can learn more about it.</Text>
        </Block>
      
        <Block type="Exposition" title="Approach to Treasure Town">
          <Text>As you walk down the path, you notice that the mountain is surrounded by dangerous cliffs. It appears the only way up is a path on the other side of the town ahead.</Text>
          <Text>It's a beautiful day, and birds are playfully chasing each other in the sky. A warm breeze glides across the rolling hills, occasionally causing flower petals to swirl in the air around you. Everything here feels pleasantly touched by a little bit of magic.</Text>
          <Text>As you draw closer, you see a collection of idyllic homes surrounding a museum. You see a few dozen people joyfully milling about, tending to gardens, playing games, and having conversations. The town is adorned with colorful garlands, flags, and other warm decorations. You know this is a strange place, but it seems to be inviting you home.</Text>
          <Text>The townspeople smile at you and go about their business as if you are familiar guests. As you walk into the town, you notice a woman in front of the museum who seems to be waiting for you.</Text>
        </Block>
      </Section>
      
      <Section type="Context">
        <Block type="Exposition" title="Meeting Fiona">
          <Text>The woman in front of the museum waves you over with a smile. She is neatly-dressed in a white tunic with a golden belt and a blue bow tie around her neck. She is holding a thick leather-bound notebook in her hands that says "directory" on its cover.</Text>
        </Block>

        <Block type="Dialog" title="Fiona's Welcome">
          <Text>Hello, adventurers! My name is Fiona Fairweather, and I am pleased to welcome you to Mischief Mountain. You have been invited to try The Trickster’s Test. To pass the test, you must reach the top of the mountaintop tower and defeat The Trickster.</Text>
          <Text>If you pass the test, you will win something from our incredible collection of treasures.</Text>
          <Text>If you want to turn back, you are now free to leave. But if you decide to play the game, there is no turning back.</Text>
          <Text>If you choose to play, you will be allowed to browse our collection of treasure and take one item to help you pass the test. If you win, you get to keep the item that you chose. Just... be warned! The more valuable the treasure you choose, the more challenging the test will be. Choose wisely.</Text>
          <Text>If you lose, well, I can’t tell you what happens. That’s all part of the game. So... do you want to play?</Text>
        </Block>
      
        <Block type="Open Context" title="What Fiona Knows">
          <Text>Fiona is only allowed to give a very limited amount of information about The Trickster's test, but she is open about the fact that the adventurers are trapped in a shadow plane. She will refuse to answer detailed questions about The Trickster or the challenge, and will tell the adventurers that the mystery is part of the game.</Text>
          <Text>The players will be given a Skycaller Amulet that can be used to communicate with Fiona during the test. She will warn them if they are off track.</Text>
          <Text>She can tell the players that there are only two rules to the game: the adventurers may only take one treasure item from the museum. And once they leave the town to ascend the mountain, they may not turn back.</Text>
        </Block>
      </Section>

      <Section type="Encounter">
        <Block type="Open Context" title="Entering the Museum">
          <Text>Fiona will guide the players into the museum. They are free to each take one item from the list below. (You may choose more items, or create your own, if you want.) If the players choose rare or legendary items, Fiona will warn them that the test will be more difficult.</Text>
          <Text>You can find full descriptions for each treasure item in the Game Book. If you have the Quest Adventure Deck, feel free to hand the full treasure cards out to the players to let them choose.</Text>
          <Text>When the players are ready to leave, Fiona escorts them out of the museum and points them toward a door that leads to a path up the side of the mountain.</Text>
        </Block>
        
        <Block type="Dialog" title="Fiona's Request">
          <Text>One more thing... before you enter the museum I’ll need you to put on these hats! We have a strict dress code in Treasure Town.</Text>
          <Text>Don’t worry, you can take them off when you leave.</Text>
        </Block>

        <Block type="Secret Context" title="Hatbats" other={<Image src={hatbatImage} alt="Hatbats" sx={{ display: "block", maxWidth: 320, mx: "auto" }} />}>
          <Text>Each player must put on a hat before entering the museum. The hats are actually hatbats: vampiric, shapeshifting bats that often take the form of hats to lure in their prey. Fiona has tamed these hatbats to act as protectors of the museum.</Text>
          <Text>If any players attempt to remove the hat while inside of the museum, or if they attempt to take more than one piece of treasure, the hatbat will let loose its fangs and bite their head for 2 HP of damage.</Text>
        </Block>

        <Block type="Secret Context" title="Recommended Treasure">
          <Text as="p" variant="body">Fiona will guide the players into the museum. They are free to each take one item from the list below. (You may choose more items, or create your own, if you want.) If the players choose rare or legendary items, Fiona will warn them that the test will be more difficult.</Text>
          <Text as="p" variant="body">You can find full descriptions for each treasure item in the <Link to="/store/game-book"><Text as="span" variant="link">Game Book</Text></Link>. If you have the <Link to="/store/adventure-deck"><Text as="span" variant="link">Quest Adventure Deck</Text></Link>, feel free to hand the full treasure cards out to the players to let them choose.</Text>
        </Block>

        <Block type="Open Context" title="Treasure Items">
          <Text>
            Uncommon Items:&nbsp;
            <PageNumber digital="133" physical="176">Blink Blade</PageNumber>, <PageNumber digital="135" physical="178">Goat Simulator</PageNumber>,  <PageNumber digital="136" physical="179">Mischievous Marbles</PageNumber>,  <PageNumber digital="137" physical="180">Quiplasher</PageNumber>,  <PageNumber digital="139" physical="182">The Catcher</PageNumber>,  <PageNumber digital="139" physical="182">The Howdy Doodat</PageNumber>,  <PageNumber digital="139" physical="182">The Sure Shot</PageNumber>
          </Text>

          <Text>
            Rare Items:&nbsp;
            <PageNumber digital="141" physical="184">Accursed Band</PageNumber>, <PageNumber digital="141" physical="184">Chaos Pearls</PageNumber>, <PageNumber digital="144" physical="187">Scintillating Wand</PageNumber>, <PageNumber digital="144" physical="187">Scrying Sphere</PageNumber>, <PageNumber digital="145" physical="188">Spirit Trap</PageNumber>, <PageNumber digital="145" physical="188">The Bouncer</PageNumber>, <PageNumber digital="145" physical="188">The Eager Edge</PageNumber>
          </Text>
          
          <Text>
            Legendary Items:&nbsp;
            <PageNumber digital="147" physical="190">Conway'S Comrades</PageNumber>, <PageNumber digital="148" physical="191">Fable</PageNumber>, <PageNumber digital="148" physical="191">Guile</PageNumber>, <PageNumber digital="149" physical="192">June'S Blanket</PageNumber>, <PageNumber digital="150" physical="193">Nightmare</PageNumber>, <PageNumber digital="150" physical="193">Multivious Map</PageNumber>, <PageNumber digital="150" physical="193">Prismatic Circlet</PageNumber>
          </Text>
        </Block>
      </Section>
    </Part>
  )
}
